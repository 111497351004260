.layout-container {
  max-width: 95%;
  margin: 0 auto;
}

.floating-top-btn {
  position: fixed;
  bottom: 5%;
  right: 3%;
  background: #2044a2;
  z-index: 10;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.4s ease-in-out;
}

.floating-top-btn:hover {
  color: #2044a2;
  background: white;
  border: 1px solid rgba(202, 199, 199, 0.105);
}

.floating-top-btn:active {
  color: #2044a2;
  background: white;
  border: 1px solid rgba(128, 128, 128, 0.105);
  padding: 15px 20px;
}

@media (max-width: 1200px) {
  .floating-top-btn {
    padding: 8px 12px;
  }
}

@media (max-width: 992px) {
  .floating-top-btn {
    padding: 25px 35px;
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .floating-top-btn {
    padding: 5px 8px;
  }
}

@media (max-width: 576px) {
  .floating-top-btn {
    padding: 4px 6px;
  }
}

button.get-a-qoute {
  position: fixed;
  z-index: 10;
  right: -2px;
  top: 50%;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transform: rotate(270deg) translateY(100%);
  -ms-transform: rotate(270deg) translateY(100%);
  transform: rotate(270deg) translateY(100%);
  background: #2044a2;
  padding: 11px 20px;
  color: white;
  outline: none;
  border: none;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}

@media (min-width:350px) and (max-width:469px) {
  button.get-a-qoute {
    top: 35%;
  }
}

@media (min-width:469px) and (max-width:759px) {
  button.get-a-qoute {
    top: 42%;
  }
}

@media (min-width:760px) and (max-width:1100px) {
  button.get-a-qoute {
    top: 40%;
  }
}

button.get-a-qoute:hover {
  padding: 11px 25px;
  background: #fff;
  color: #2044a2;
  border: 1px solid rgb(184, 183, 182);
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 15px 3px rgba(0, 0, 0, 0.1);
}

.get-a-qoute-floating {
  background: #2044a2;
  width: 150px;
  height: 100px;
  border: none;
  outline: none;
  color: white;
  position: fixed;
  bottom: 5%;
  right: 3%;
  top: 50%;
  right: 0;
  z-index: 10;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.read-more-btn {
  color: rgb(2, 2, 2);
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}

.read-more-btn:hover {
  color: rgb(20, 20, 20);
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}

.read-more-btn .icon-arrow {
  width: 35px;
  background: #2044a2;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

.read-more-btn:hover .icon-arrow {
  background: #ffffff;
  color: #2044a2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
}

@media (hover: hover) {

  /* Normal hover styles for devices that support hover */
  .service-button:hover {
    background: #2044a2;
    color: white;
    outline: none;
  }
}

/* Touch event styles for devices that don't support hover or have touch screens */
.service-button:active {
  background: #2044a2;
  color: white;
  outline: none;
}

.custom-pagination-button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 0px;
  text-align: center;
  background: white;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s, boxShadow 0.3s;
  margin-right: 15px;
}

.custom-pagination-button:hover {
  background: #2044a2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, color 0.3s, boxShadow 0.3s;
  color: white;
}

.custom-pagination-mu {
  padding-bottom: 60px;
  margin-top: -50px
}

@media (min-width:360px) and (max-width:760px) {
  .custom-pagination-mu {
    padding-bottom: 20px;
    margin-top: -30px
  }
}

.service-button {
  padding: 6px 10px;
  border: 1px solid gray;
  border-radius: 60%;
  margin: 5px 5px;
}

@media (min-width: 360px) and (max-width: 480px) {
  .service-button {
    padding: 6px 10px;
  }
}

@media (min-width: 480px) and (max-width: 760px) {
  .service-button {
    padding: 6px 10px;
  }
}

@media (min-width: 760px) and (max-width: 920px) {
  .service-button {
    padding: 6px 10px;
  }
}

@media (min-width: 920px) and (max-width: 1200px) {
  .service-button {
    padding: 28px 30px;
  }

  .read-more-btn .icon-arrow {
    width: 70px;
    background: #2044a2;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
  }
}
