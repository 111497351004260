.aboutnew-story-bg {
  background-image: url("../../../images/AboutStory/spare-trans.webp");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 60px;
  color: #fff;
}

.aboutnew-story-text {
  color: white;
  padding-right: 20px;
}
.story-description{
  word-break: break-all;
}

@media (min-width: 360px) and (max-width: 760px) {
  .aboutnew-story-text h2 {
    padding: 0px 10px 10px 10px;
  }

  .aboutnew-story-text p {
    padding: 0px 10px 10px 10px;
  }
  
  .aboutnew-story-bg {
    margin-top: 50px;
    padding: 20px;
      }
}
.OurStoryImage{
  width: 50%;
  border: 13px solid #fff;
  margin:0 20px 20px 0;
}
.story-description{
  word-break: break-all;
}

@media  (min-width: 992px){
  .story-description{
    word-break: break-all;
  }
  
}