.specialised-service {
  .bg-container{
    
  background-image:url('/images/cargo-mixed.webp') ;
  
  }
  .image-cards-container {
    color: white;
    margin-bottom: 30px;
  }
  .image-cards-container:nth-child(1),
  .image-cards-container:nth-child(3) {
    .image-cards-left {
      height: 400px;
    }
    .image-cards-right {
      // background: transparent;
      color: white;
    }
    .image-cards-right {
      h1 {
        font-size: 25px;
      }
    }
  }
  .image-cards-container:nth-child(2) {
    .image-cards-left {
      background: transparent;
      color: #000;
      order: 2;
    }
    .image-cards-right {
      height: 400px;
      order: 1;
      margin-bottom: 20px;
    }
  }
}

.section {
  margin-bottom: 20px;

  .section-background {
    width: 100%;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    object-fit: cover;
    display: none;
  }

  .single-row {
    margin-bottom: 20px;
  }
  .single-card {
    height: 100%;
    border: none;
    padding: 15px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    .card-image {
      max-width: 100%;
      height: auto;
    }

    &:hover {
      background-color: #f1f1f1;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
      transform: translateY(-5px);
    }
  }
}

@media (min-width: 992px) {
  .section {
    background-image: url("../../../public/images/CargoService/cargo-mixed.webp");
    background-size: 100% 75%;
    background-position: center;
    background-repeat: no-repeat;
    .section-background {
      display: block;
    }
  }
}

@media (minWidth: 1400px) {
  .height-95 {
    height: 95% !important;
  }
  .section {
    // height: 70vh;
    margin-bottom: 180px;
  }
}
@media (minWidth: 1600px) {
  .height-95 {
    height: 95% !important;
  }
  .section {
    // height: 100vh;
    margin-bottom: 0px;
  }
}




@media (min-width: 768px) {
  .specialised-service {
    .image-cards-container:nth-child(1),
    .image-cards-container:nth-child(3) {
      .image-cards-left {
        display: flex;
      }
      .image-cards-right {
        background: url("/images/AboutStory/spare-trans.webp");
        color: #ffff;
      }
    }
    .image-cards-container:nth-child(2) {
      .image-cards-left {
        color: #000;
        order: 1;
      }
      .image-cards-right {
        display: flex;
        order: 2;
      }
    }
  }
}
@media (min-width: 992px) {
  .specialised-service {
    .image-cards-container:nth-child(1),
    .image-cards-container:nth-child(3) {
      .image-cards-left {
        display: flex;
        height: auto;
      }
      .image-cards-right {
        background: url("/images/AboutStory/spare-trans.webp");
        color: #ffff;
      }
    }
    .image-cards-container:nth-child(2) {
      .image-cards-left {
        color: #000;
        order: 1;
      }
      .image-cards-right {
        display: flex;
        height: auto;
        order: 2;
      }
    }
  }
}

.service-row-heading {
  color: rgb(14, 14, 14);
}

@media (min-width: 576px) {
  .service-row-heading {
    color: rgb(255, 255, 255);
  }
}
