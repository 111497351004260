.custom-input-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  label {
    display: block;
    color: #fff;
    padding-bottom: 0px;
  }
  input,
  textarea {
    width: 100%;
    display: block;
    outline: none;
    color: #fff;
    border: 1px solid #fff;
    padding: 8px;
    border-radius: 7px;
    font-size: 12px;
    background: transparent;
    margin-bottom: 0;
    &::placeholder {
      color: rgba(243, 243, 243, 0.3);
      font-size: 12px;
      padding: 1px;
      width: 100%;
    }
  }
}
