@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

.highlights-bg {
  background: linear-gradient(to right, #193377, #009adc);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.high-txt-stl {
  /* font-size: 70px; */
  color: white;
  padding: 60px;
  text-align: none;
}

.highlights-header {
  color: white;
  padding-left: 10%;
  padding-top: 5%;
}

.highlights-parag {
  width: 80%;
  font-size: 12px;
}

.bi-chevron-down {
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px 7px 5px 7px;
}

.read-highlights {
  padding-right: 21px;
}

.highlights-url {
  font-size: 12px;
}

.highlight-item {
  background: linear-gradient(to top, #193377, #009adb);
  border: 1px solid rgb(236, 236, 236, 0.8);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  width: auto;
  height: 300px;
  color: #ebebeb;
}

.swiper-slide-higlight:hover {
  background: linear-gradient(to bottom, #193377, #0072a2);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
}

/* Add more animations */
.swiper-slide-higlight:hover img {
  transform: scale(1.01);
  transition: transform 0.3s ease-in-out;
}

.swiper-slide-higlight:hover h1 {
  transform: translateY(-10px);
  transition: transform 0.3s ease-in-out;
}

.highlights-text {
  font-size: 20px;
}

.multi-pad {
  padding-top: 85px;
}

.multi-pad-second {
  padding-top: 30px;
}

.highlight-text-one {
  font-size: 18px;
  justify-content: center;
  padding-bottom: 70px;
  text-align: center;
  position: relative;
  font-weight: 100;
  color: rgb(255, 255, 255, 0.8);
}

.highlight-out-mg {
  margin-right: 140px;
}

.swiper-slide-higlight {
  width: 385px;
  padding: 0;
}

.highlights-container-bg {
  background-image: url("../images/Highlights/highlights-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px;
  margin: 0;
  border: 0 !important;
  padding-top: 100px;
  margin-top: 30px;
}

.highlights-contianer-bg .row {
  border: none;
}

.highlights-contianer-bg h1 {
  color: white;
}

.highlights-contianer-bg p {
  color: white;
}

.fa-chevron-down-custom {
  border-radius: 50%;
  border: 1px solid #a7a7a7; /* Add a white border */
}

.read-icon-a a {
  color: white;
}

@media (max-width: 460px) {
  .highlights-bg {
    background: linear-gradient(to right, #193377, #009adb);
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: auto;
  }

  .high-txt-stl {
    font-size: 40px;
    color: white;
    padding: 0px;
    margin-bottom: 30px;
    margin-top: 0;
    text-align: center;
  }

  .highlight-item {
    background: linear-gradient(to top, #193377, #009adb);
    border: 1px solid rgb(236, 236, 236, 0.8); 
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #ebebeb;
    height: 240px;
  }
  .highlight-text-one {
    font-size: 14px;
    justify-content: center;
    padding-bottom: 40px;
    text-align: center;
    position: relative;
    font-weight: 100;
    color: rgb(255, 255, 255, 0.8);
  }
  .multi-pad {
    padding-top: 17px;
  }

  .highlights-container-bg {
    padding: 38px;
  }

  .highlights-header {
    color: white;
    padding: 10%;
    padding-top: 5%;
  }
}

/* Highlights */
.highlights-bg-second {
  background: linear-gradient(to right, #193377, #009adb);
  width: auto;
  height: auto;
}

.highlights-text {
  color: white;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 30px;
}

.highlights-f-mode {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 30px;
  justify-content: center;
  text-align: center;
}

.card-wrap-second {
  flex: 0 0 auto;
  scroll-snap-align: start;
  flex: 1;
}

.highlight-item-second {
  background: linear-gradient(to top, #193377, #009adb);
  border: 1px solid rgb(236, 236, 236, 0.8);
  background-size: cover;
  background-repeat: no-repeat;
  color: #ebebeb;
  padding: 20px;
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center; 
}

.highlights-second-head{
  padding: 40px 0px;
}