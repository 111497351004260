.simplyAboutTop {
  padding-top: 360px;
  padding-bottom: 20px;
  text-align: center;
}

.about-simply-head-main{
  font-weight: 800;
}

.simplyAboutBg {
  background: linear-gradient(to bottom, #193377, #009adb);
  background-repeat: no-repeat;
  text-align: center;
  height: 100%;
  border: 0px;
  border-radius: 10px;
}

.simplyAboutBg:hover {
  background: linear-gradient(to right, #193377, #009adb);
  transform: scale(1.02);
  transition: background 0.3s ease, transform 0.3s ease;
}

.simplyAboutBg img {
  width: 150px;
}

.simplyAboutHeadOne {
  padding-left: 70px;
  padding-right: 70px;
}

.simplyAboutBgOne {
  padding: 35px;
  color: white;
}

.simplyAboutHead {
  font-size: 50px;
  font-weight: 800;
}

.simplyAboutHeading {
  color: white;
  font-weight: 500;
}

.simplyAboutParag {
  font-size: 16px;
  text-align: center;
  color: rgb(255, 255, 255);
  padding-top: 10px;
  word-break: normal;
}

.simplyAboutParagh {
  padding-bottom: 10px;
  text-align: center;
}

/* Media Query for 321px to 480px */
@media (maxWidth: 480px) {
  .simplyAboutTop {
    padding-top: 50px;
    padding-bottom: 0px;
    text-align: center;
  }

  .simplyAboutBg {
    background: linear-gradient(to bottom, #193377, #009adb);
    background-repeat: no-repeat;
    text-align: center;
    height: 100%;
    border: 0px;
    border-radius: 10px;
  }



  .simplyAboutHeadOne {
    padding-left: 20px;
    padding-right: 20px;
  }

  .simplyAboutBgOne {
    padding: 30px;
  }

  .simplyAboutHead {
    font-size: 35px !important;
    font-weight: 800;
  }

  .simplyAboutHeading {
    color: white;
    font-weight: 500;
  }

  .simplyAboutParag {
    font-size: 14px;
    text-align: center;
    color: rgb(255, 255, 255);
    padding-top: 10px;
    word-break:normal;
  }

  .simplyAboutParagh {
    padding-bottom: 10px;
    text-align: center;
  }
}

/* Media Query for 481px to 600px */
@media (maxWidth: 600px) {
  .simplyAboutTop {
    padding-top: 80px;
    padding-bottom: 5px;
    text-align: center;
  }

  .simplyAboutBg {
    background: linear-gradient(to bottom, #193377, #009adb);
    background-repeat: no-repeat;
    text-align: center;
    height: 100%;

    border: 0px;
    border-radius: 10px;
  }



  .simplyAboutHeadOne {
    padding-left: 40px;
    padding-right: 70px;
  }

  .simplyAboutBgOne {
    padding: 30px;
  }

  .simplyAboutHead {
    font-size: 40px;
    font-weight: 800;
  }

  .simplyAboutHeading {
    color: white;
    font-weight: 500;
    text-align: center;
  }

  .simplyAboutParag {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
    padding-top: 10px;
    word-break: normal;
  }

  .simplyAboutParagh {
    padding-bottom: 10px;
    text-align: center;
  }
}

@media (minWidth: 769px) and (maxWidth: 900px) {
  .simplyAboutTop {
    padding-top: 50px;
    padding-bottom: 5px;
    text-align: center;
  }

  .simplyAboutBg {
    background: linear-gradient(to bottom, #193377, #009adb);
    background-repeat: no-repeat;
    text-align: center;
    height: 100%;
    border: 0px;
    border-radius: 10px;
  }



  .simplyAboutHeadOne {
    padding-left: 70px;
    padding-right: 70px;
  }

  .simplyAboutBgOne {
    padding: 30px;
  }

  .simplyAboutHead {
    font-size: 40px;
    font-weight: 800;
  }

  .simplyAboutHeading {
    color: white;
    font-weight: 500;
  }

  .simplyAboutParag {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);
    padding-top: 10px;
    word-break: normal;
  }

  .simplyAboutParagh {
    padding-bottom: 10px;
    text-align: center;
  }
}
