.network-bg-main {
  width: 100%;
  height: 75vh;
  background: url("../images/Network/GlobalMap-new.webp");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.car-service-cards-heading {
  font-size: 60px;
}

.focus-outline-none:focus,
.focus-outline-none:focus-visible {
  outline: none;
}

.map-svg {
  cursor: pointer;
  width: 95%;
  height: 95%;
}

.hover-shadow {
  filter: drop-shadow(14px 5px 4px rgba(0, 0, 0, 0.5));
}

.our-network-svg path {
  fill: #125194;
  stroke: #fff;
  stroke-width: 1.6;
  stroke-dasharray: 5 5;
}

.our-network-svg path:hover {
  fill: #1890F9;
  filter: drop-shadow(14px 5px 4px rgba(0, 0, 0, 0.5));
}

/* Extra Small Devices, Phones */
@media (max-width: 576px) {
  .network-bg-main {
    height: 100%;
  }

  .car-service-cards-heading {
    font-size: 36px;
  }

  .map-svg {
    width: 90%;
    height: 90%;
  }
}

/* Small Devices, Phones */
@media (min-width: 576px) and (max-width: 768px) {
  .network-bg-main {
    height: 100%;
  }

  .car-service-cards-heading {
    font-size: 40px;
  }

  .map-svg {
    width: 85%;
    height: 85%;
  }
}

/* Medium Devices, Tablets */
@media (min-width: 768px) and (max-width: 992px) {
  .network-bg-main {
    height: 100%;
  }

  .car-service-cards-heading {
    font-size: 48px;
  }

  .map-svg {
    width: 75%;
    height: 86%;
  }
}

/* Large Devices, Desktops */
@media (min-width: 992px) {
  .network-bg-main {
    height: 100vh;
  }

  .car-service-cards-heading {
    font-size: 54px;
  }

  .map-svg {
    width: 75%;
    height: 76%;
  }
}

/* Extra Large Devices, Large Desktops */
@media (min-width: 1400px) {
  .map-svg {
    width: 75%;
    height: 76%;
  }
}
