/* Left Section */
.contact-us-arrange {
  padding-top: 15%;
}

.contact-us-heads {
  font-weight: 700;
  font-size: 80px;
  line-height: 100px;
  padding-bottom: 20px;

  @media (max-width: 460px) {
    font-size: 50px;
    line-height: 65px;
  }
}

.contact-us-paragh {
  font-size: 14px;
  width: 90%;
  text-align: justify;
}

// Contact us container top padding

/* Right Section */
.contact-us-split-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  width: 90%;
  padding-top: 50px 0;

  @media (max-width: 460px) {
    padding-bottom: 45px;
  }
}

/* Form Styling */
.contact-us-split-form label {
  color: #000000; /* Change to your desired text color */
  font-family: "Poppins", sans-serif;
  font-weight: 900;
}

.contact-us-transparent-input::placeholder {
  color: #a7a7a7;
}

.contact-us-btn-custom {
  background-color: #182d70;
  border: 0px solid #182d70;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.contact-us-split-form input[type="text"],
.contact-us-split-form input[type="email"],
.contact-us-split-form textarea {
  width: 100%;
  padding: 14px;
  border-radius: 10px 10px 10px 10px;
  border-color: #d3d3d3;
  // min-height: 50px;
  border: 1px solid #a7a7a7;
}

textarea {
  width: 100%;
  padding-left: 14px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #a7a7a7;
}

@media (maxWidth: 768px) {
  .contact-us-arrange {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.thank-you-message-main-form{
  background-image: linear-gradient(to top, #193377, #009ADB);
}

.errorMessage {
	color: #ff0000;
	font-size: 12px;
	margin-left: 6px;
}

.input-error {
	border-color: #ff0000 !important;
}
