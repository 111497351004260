.titleMainContainer {
  width: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 16px;
  .titleText {
    color: rgb(255, 255, 255);
    font-size: 50px;
    font-weight: 700;
    text-align: center;
  }
}




@media (maxWidth: 576px) {
  .titleMainContainer {
    max-width: 100%;
    height: 300px !important;
    .titleText {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
  }
}

@media (minWidth: 768px) {
  .titleMainContainer {
    width: 100%;
    height: 450px;
    .titleText {
      color: rgb(255, 255, 255);
      font-weight: 700;
    }
  }
}
