.custom-clr-nav-bg {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-image:
    url("../../../public/images/BlogBanner/CustomsClearance01.webp");
  background-position: bottom;
  background-repeat: no-repeat;
}

.twitter-new{
  background-image: url('../../images/icons8-twitterx-50.webp');
}

.custom-clr-quote {
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 0;
}
.custom-clr-quote-img img {
  float: right;
  width: 50px;
  padding-top: 150px;
  transform: translateX(27px);
}


.custom-clr-text {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: 700;
  padding-top: 200px;
}

.custom-clr-text1 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  width: 80%;
  padding-left: 300px;
}
/* nav-bg text */
.custom-clr-text-nav {
  font-size: 16px;
  font-weight: 800;
  border: 1px solid rgb(239, 239, 239);
  background-color: rgb(239, 239, 239);
  padding: 30px;
  text-align: center;
  justify-content: center;
}

.custom-clr-text-nav-pad {
  padding-left: 96px;
  padding-right: 96px;
  padding-bottom: 20px;
}


@media (max-width: 460px){
  .custom-clr-text-nav-pad {
    padding-bottom: 0px;
  }

  .custom-clr-text {
    color: rgb(255, 255, 255);
    font-size: 23px;
    font-weight: 650;
}
.date-time-fix{
  display: none
      }
  }

.custom-clr-txt-pad{
  padding-top: 25px;
}

.date-time-fix{
  color: rgb(216, 216, 216);
  padding-top: 130px;
  padding-left: 100px;
  font-size: 14px;
}

.date-fixx-icon img {
  width: 17px;
  transform: scale(1);
}

.date-fixx-icon img:hover {
  transform: scale(1.01);
}

.date-fixx-icon{
  padding-top: 10px;
padding-right: 100px;
}

.bi-custom-bi-ch{
  margin-left: 17px;
}

@media (max-width: 459px) {
  .custom-clr-quote-img img {
    width: 30px;
    padding-top: 100px;
    position: fixed;
    justify-content: right;
    right: 0;
    transform: translateX(0px);
  }

  .custom-clr-text {
    color: rgb(255, 255, 255);
    font-size: 25px;
    font-weight: 700;
    padding-top: 70px;
  }

  .custom-clr-nav-bg {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-image: linear-gradient(rgba(75, 75, 75, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../public/images/BlogBanner/CustomsClearance01.webp");
  background-position: bottom;
    margin-bottom: 15px;
  }
  
  .custom-clr-text1 {
    color: rgb(255, 255, 255,0.8);
    font-size: 13px;
    padding-top: 5px;
  }

  .date-time-fix{
    color: rgb(216, 216, 216);
    padding-top: 70px;
    padding-left: 110px;
    font-size: 13px;
    
  }
  .date-fixx-icon img {
display: hidden;
  }
}
