.achievement-bg-first {
  position: relative;
  height: auto;
  width: 100%;
  padding: 20px;
  background-position:center;
  background-image: url('./../images/Achievement/Achievements bc.webp');
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.achievement-heading-sized-one{
  padding-bottom: 10px;
  font-size: 60px;
}

.achievement-bg-first:hover {
  background-image: url('./../images/Achievement/Achievements bc.webp'); 
  transform: scale(1.02); 
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.achie-head-sub{
  font-size: 20px;
  padding-top: 8px;
  font-weight: 700;
}


.achievement-card {
  border-radius: 10px;
  text-align: center;
}

.achievement-card img{
  width: 100%;
  height: auto;
}

.achievement-r-response{
  margin: 0px;
}

@media (max-width: 360px) {
  .achiev-heading-width {
    font-size: 30px;
    font-weight: 800;
  }

  .achievement-bg-first {
    padding: 15px;
  }

  .achievement-heading-sized-one {
    padding-bottom: 0px;
  }
}

@media (max-width: 460px) {
  .achiev-heading-width {
    font-size: 35px;
    font-weight: 800;
    padding-bottom: 20px;
  }

  .achievement-bg-first {
    padding: 15px;
  }

  .achievement-heading-sized-one {
    padding-bottom: 0px;
  }
}

.achie-button{
  margin-bottom: 20px;
}

.achie-space-marg-second{
  margin-bottom: 30px;
}