.ind-oil-start {
  width: 90%; /* Set the container width to 80% */
  margin: 0 auto; /* Center the container horizontally */
  padding: 60px 0;
}

.ind-oil-left {
  background-size: cover;
  height: 700px;
}

.ind-oil-right {
  background-size: cover;
  height: 700px;
  background-image: url("../../images/Ind-Oil-Gas/Ind-Oil-Gas-bg.webp");
}

.ind-oil-left {
  background-image: url("../../images/Ind-Oil-Gas/oil-gas.webp");
}

.content-overlay {
  padding: 20px;
  color: white;
}

.ind-oil-head {
  padding-top: 120px;
}

.ind-oil-parag {
  padding-top: 20px;
  font-size: 18px;
  text-align: justify;
}

.ind-oil-bi {
  padding-left: 15px;
  font-size: 30px;
}

.ind-oil-parag-one {
  padding-top: 20px;
}

.ind-oil-learn {
  font-size: 18px;
  text-decoration: none;
  color: white;
}

.ind-oil-start {
  width: 90%;
  margin: 0 auto;
  padding: 60px 0;

  @media (maxWidth: 768px) {
    width: 100%; /* Full width on smaller screens */
    padding: 25px;
  }
}

.ind-oil-left {
  background-size: cover;
  height: 700px;

  @media (maxWidth: 768px) {
    height: 350px; /* Adjust height for smaller screens */
  }
}

.ind-oil-right {
  background-size: cover;
  height: 700px;

  @media (maxWidth: 768px) {
    height: 770px; /* Adjust height for smaller screens */
  }

  @media (max-width: 460px) {
    height: 650px;
  }
}

.content-overlay {
  padding: 20px;
  color: white;

  @media (maxWidth: 768px) {
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

.ind-oil-head {
  padding-top: 120px;

  @media (maxWidth: 768px) {
    padding-top: 20px; /* Adjust padding-top for smaller screens */
  }
}

.ind-oil-parag {
  padding-top: 20px;
  font-size: 18px;
  text-align: justify;

  @media (maxWidth: 768px) {
    font-size: 16px;
    padding-top: 10px; /* Adjust font size for smaller screens */
  }
}

.ind-oil-bi {
  padding-left: 15px;
  font-size: 30px;

  @media (maxWidth: 768px) {
    font-size: 24px; /* Adjust font size for smaller screens */
  }
}

.ind-oil-parag-one {
  padding-top: 20px;

  @media (maxWidth: 768px) {
    padding-top: 10px; /* Adjust padding-top for smaller screens */
  }
}

.ind-oil-learn {
  font-size: 18px;
  text-decoration: none;
  color: white;

  @media (maxWidth: 768px) {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 460px) {
  .ind-oil-right {
    height: auto;
  }

  .ind-oil-left {
    background-size: cover;
    height: 300px;
  }

  .ind-oil-parag-one {
    padding-top: 0px;
  }
  .ind-oil-head {
    padding-top: 20px;
  }

  .ind-oil-parag {
    padding-top: 5px;
    font-size: 16px;
    text-align: justify;
  }
}

.industries {
  .image-cards-container {
    color: white;
    margin-bottom: 30px;
    &:nth-child(even) {
      flex-direction: row-reverse;
      .image-card-content{
        background-image: none;
        color: #000;
      }
    }
    .image-card-image,
    .image-card-content{
      padding: 0;
      img{
        height: 100%;
        min-height: 40vh;
        width: 100%;
        object-fit: cover;
       
      }
    }
    .image-card-content {
      background-image: url("../../images/AboutStory/spare-trans.webp");
      padding: 5vw;
    }
  }
}

@media (min-width: 768px) {
  .industries {
    .image-cards-container:nth-child(1),
    .image-cards-container:nth-child(3) {
      .image-cards-left {
        display: flex;
      }
      .image-cards-right {
        background: url("/images/AboutStory/spare-trans.webp");
        color: #ffff;
      }
    }
    .image-cards-container:nth-child(2) {
      .image-cards-left {
        color: #000;
        order: 1;
      }
      .image-cards-right {
        display: flex;
        order: 2;
      }
    }
  }
}
.lottieContainer {
  height: 300px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .industries {
    .image-cards-container:nth-child(1),
    .image-cards-container:nth-child(3),
    .image-cards-container:nth-child(5),
    .image-cards-container:nth-child(7),
    .image-cards-container:nth-child(9) {
      .image-cards-left {
        display: flex;
        height: auto;
      }
      .image-cards-right {
        background: url("/images/AboutStory/spare-trans.webp");
        color: #ffff;
      }
    }
    .image-cards-container:nth-child(2),
    .image-cards-container:nth-child(4),
    .image-cards-container:nth-child(6),
    .image-cards-container:nth-child(8) {
      .image-cards-left {
        color: #000;
        order: 1;
      }
      .image-cards-right {
        display: flex;
        height: auto;
        order: 2;
      }
    }
  }
}
