.header-main {
    padding: 0 5vw;
    min-height: 100vh;
    background-position: right center;
    background-size: 950px;
    background-repeat: no-repeat;
    .home-navbar {
        &:not(.sticky) {
            & > .container-fluid {
                width: 100% !important;
                ul.nav {
                    padding: 0 !important;
                }
                .navbar {
                    max-width: 100% !important;
                }
            }
        }
    }
    .header-container {
        .left-pane {
            max-width: 55%;
            h1 {
                font-size: 70px;
                margin-top: 10vh;
            }
            .subtitle {
                font-size: 33px;
                font-weight: 400; 
                margin-top: 25px;
            }
            .description {
                font-size: 15px;
                font-weight: 500;
                margin-top: 20px;
                max-width: 70%;
            }
            .read-more-btn {
                .read-more-btn-link {
                    display: flex;
                    align-items: center;
                    color: #020202;
                    text-decoration: none;
                    gap: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .header-main {
        background-position: center 35%;
        background-size: 120vw;
        background-repeat: no-repeat;
        min-height: 80vh;
        .header-container {
            .left-pane {
                max-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 87vw;
                text-align: center;
                h1 {
                    // font-size: 55px;
                    margin-top: 10vh;
                }
                .subtitle {
                    font-size: 24px;                    
                    margin-top: 25px; 
                }
                .description {
                    margin-top: 20px;
                    font-size: 14px;
                    max-width: 100%;
                }

                .read-more-btn {
                    .read-more-btn-link {
                        margin-top: 20px;
                        font-size: 27px;
                    }
                }
            }
        }
    }  
}

@media screen and (max-width: 768px) {
    .header-main {
        background-size: 150vw;
        min-height: 60vh;
        .header-container {
            .left-pane {
                padding-top: 82vw;
                h1 {
                    font-size: 50px;
                    margin-top: 20px;
                }
                .subtitle {
                    margin-top: 20px;
                }

                .read-more-btn {
                    .read-more-btn-link {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:540px)  {
    .header-main {
        background-size: 150vw;
        min-height: 58vh;
        .header-container {
            .left-pane {
                padding-top: 87vw;
                h1 {
                    font-size: 48px;
                    margin-top: 80px;
                }
                .subtitle {
                    margin-top: 20px; 
                }
                .read-more-btn {
                    .read-more-btn-link {
                    margin-top: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .header-main {
        background-size: 150vw;
        min-height: 50vh;
        .header-container {
            .left-pane {
                 padding-top: 100vw;
                h1 {
                    font-size: 45px;
                    margin-top: 10px;
                }
                .subtitle {
                    margin-top: 10px; 
                }
                .read-more-btn {
                    .read-more-btn-link {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}


