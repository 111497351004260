.section-container {
  .content-container {
    background-image: url("../../images/AboutStory/spare-trans.webp");
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    .content {
      color: inherit;
      width: 100%;
      
         }
  }
}
@media (min-width: 768px) {
  .section-container {
    .content-container {
      background-image: url("../../images/AboutStory/spare-trans.webp");
      padding: 60px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      .content {
        color: inherit;
        width: 75%;
      }
    }
  }
}
.read-more-ltr {
  color: white;
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}
.read-more-ltr:hover {
  color: white;
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}
.read-more-ltr .icon-arrow {
  background: #2044a2;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
.read-more-ltr:hover .icon-arrow {
  background: #ffffff;
  color: #2044a2;

  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
}
.read-more-rtl:hover .icon-arrow i {
  font-weight: 800;
}

@media (min-width: 768px) {
  .read-more-ltr {
    color: white;
    text-decoration: none;
    display: flex;
    background: transparent;
    align-items: center;
  }
  .read-more-ltr:hover {
    color: white;
    text-decoration: none;
    display: flex;
    background: transparent;
    align-items: center;
  }
  .read-more-ltr .icon-arrow {
    background: #ffffff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #2044a2;
  }
  .read-more-ltr:hover .icon-arrow {
    background: #2044a2;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-weight: 500;
  }
  .read-more-rtl:hover .icon-arrow i {
    font-weight: 800;
  }
}

.read-more-rtl {
  color: white;
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}
.read-more-rtl:hover {
  color: white;
  text-decoration: none;
  display: flex;
  background: transparent;
  align-items: center;
}
.read-more-rtl .icon-arrow {
  background: #2044a2;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
.read-more-rtl:hover .icon-arrow {
  background: #ffffff;
  color: #2044a2;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
}
.read-more-rtl:hover .icon-arrow i {
  font-weight: 800;
}
@media (min-width: 768px) {
  .read-more-rtl {
    color: white;
    text-decoration: none;
    display: flex;
    background: transparent;
    align-items: center;
  }
  .read-more-rtl:hover {
    color: white;
    text-decoration: none;
    display: flex;
    background: transparent;
    align-items: center;
  }
  .read-more-rtl .icon-arrow {
    background: #2044a2;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
  }
  .read-more-rtl:hover .icon-arrow {
    color: #2044a2;
    background: #ffffff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 500;
  }
  .read-more-rtl:hover .icon-arrow i {
    font-weight: 800;
  }
}
