.social-icons .nav-link-navbar-main {
    font-size: 14px; /* Base font size */
    padding: 0px 6px;
}

@media (min-width: 768px) {
    .social-icons .nav-link-navbar-main {
      font-size: 16px; 
      padding: 0px 10px;/* Slightly larger font size for better visibility on large screens */
    }
  }

@media (min-width: 1200px) {
    .social-icons .nav-link-navbar-main {
      font-size: 16px; 
      padding: 0px 6px;/* Slightly larger font size for better visibility on large screens */
    }
  }

  .responsive-svg {
    width: 14px;
    height: auto; /* Ensures the aspect ratio is maintained */
  }
  
  /* Adjustments for larger screens */
  @media (min-width: 768px) { /* Tablet landscape */
    .responsive-svg {
      width: 35px;
    }
  }
  
  @media (min-width: 1024px) { /* Desktop */
    .responsive-svg {
      width:15px;
    }
  }
  

  /* Adjustments for medium-sized screens */
  @media (min-width: 768px) and (max-width: 1199px) {
    .social-icons .nav-link-navbar-main {
      font-size: 40px; /* This is unusually large for typical web usage */
    }
  }

  .home-navbar.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 110;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
/* Default styles */

.sidebar-links li {
    font-size: 15px!important;
  }
  
  .sidebar-links p {
    font-size: 18px!important;
  }
  
  /* Media query for large desktops and laptops */
  @media (min-width: 1200px) {
    .sidebar-links li {
      font-size: 14px!important;
    }
    .sidebar-links p {
      font-size: 13px!important;
    }
  }
  
  /* Media query for tablets and iPads */
  @media (min-width: 768px) and (max-width: 1199px) {
    .sidebar-links li {
      font-size: 40px!important;
    }
    .sidebar-links p {
      font-size: 39px!important;
    }
  }

  .menu li,
.menu.submenu div {
  padding: 10px 15px;
}

.toggle-close-btn .bi-arrow-right::before {
  font-size: 26px;
  padding: 0px;
}


/* Media Queries */
@media only screen and (max-width: 1024px) {
  /* Styles for tablets (like iPad) and smaller */
  .menu li,
  .menu.submenu div {
    padding: 40px 12px; /* Adjust padding for smaller screens */
  }

  .toggle-close-btn .bi-arrow-right::before {
    padding: 48px;
    font-size: 60px;
  }

}

@media only screen and (max-width: 768px) {
  /* Styles for mobile devices */
  .menu li,
  .menu.submenu div {
    padding: 6px 10px; /* Further adjust padding for mobile devices */
  }
}

.rights-recieved-p{
    font-size: 10px;
    color: white;
}

/* Adjustments for larger screens */
@media (min-width: 576px) { /* Small devices (landscape phones, 576px and up) */
    .rights-recieved-p {
        font-size: 12px;
    }
}

@media (min-width: 768px) { /* Medium devices (tablets, 768px and up) */
    .rights-recieved-p {
        font-size: 20px;
    }
}

@media (min-width: 992px) { /* Large devices (desktops, 992px and up) */
    .rights-recieved-p {
        font-size: 10px;
    }
}

@media (min-width: 1200px) { /* Extra large devices (large desktops, 1200px and up) */
    .rights-recieved-p {
      padding-top:2px ;
        font-size: 11px;
    }
}

/* Base styles */
.social-icons svg, .social-icons .fa {
  font-size: 1rem;
  margin: 0px 5px;
  position: relative;
  top: 0px;
  right: 0px;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .social-icons svg, .social-icons .fa {
    font-size: 1.3rem;
    position: relative;
    top: 0px;
    right: 0px; 
  }
}

@media (max-width: 992px) {
  .social-icons svg, .social-icons .fa {
    font-size: 2.5rem;
    position: relative;
    top: 25px;
    right: 45px;
  }
}
 
/* sidebar icon */

.social-icon svg, .social-icons .fa {
  font-size: 1rem;
  margin: 0px 5px;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .social-icon svg, .social-icons .fa {
    font-size: 1.3rem;
    margin: 0px 5px;
  }
}

@media (max-width: 992px) {
  .social-icon svg, .social-icons .fa {
    font-size: 2.5rem;
    margin: 5px 10px;
  }
}