body {
  margin: 0 !important;
}

::-webkit-scrollbar {
  display: none;
}


p, h2, h3, h4, h5 ,h6 {
  font-family: "Poppins", sans-serif !important;
}


h1 {
  font-family: "Bebas Neue", sans-serif !important;
  font-weight: 800 !important;
}

button:focus {
  outline: none!important;
}

p {
  font-size: 14px !important; /* Base font size */
}

@media (min-width: 576px) { 
  p {
    font-size: 33px !important; /* Larger font size for medium devices */
    line-height: 1.5; 
  }
}

@media (min-width: 768px) {
  p {
    font-size: 33px !important; /* Same larger font size for larger devices */
    line-height: 1.5; /* Adjusted line height */
  }
}

@media (min-width: 992px) {
  p {
    font-size: 20px !important; /* Smaller font size for large devices */
    line-height: 1.5; /* Adjusted line height */
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 15px !important; /* Base font size for very large devices */
    line-height: 1.7; /* Adjusted line height */
  }
}
