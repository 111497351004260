.split-form-container {
  display: flex;
  min-height: auto;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding: 70px 0;
}

.form-label-career-choose {
  padding-top: 5px;
  padding-bottom: 20px;
}

.form-label-career-choose h6 {
  color: rgb(199, 198, 198);
  font-size: 15px;
}

.form-label-career-choose,
.selected-file {
  margin-top: 10px;
  font-size: 14px;
  color: rgb(199, 198, 198);
  font-weight: 200;
}

.split-form-image-career {
  flex: 1;
  background-image: url("../../images/Forms/form-bg.webp"),
    linear-gradient(to bottom, #193377, #009adb);
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 0;
  margin: 0;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Apply the animation to the thank-you message div */
.thank-you-message-main-form-one {
  animation: zoomIn 0.5s ease forwards;
}

/* Additional styles to center the content and set background color */
.thank-you-message-main-form-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form-label {
  font-weight: 600;
  font-size: 15px;
}

.split-form-career {
  flex: 1;
  background-color: #ececec;
  background-image: url("../../images//Forms/form-bg-2.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  display: flex;
  flex-direction: column; /* Display form elements in a column */
  align-items: center;
  /* Center align form elements horizontally */
}

.split-first-head {
  font-size: 30px;
  color: white;
  margin-right: 285px;
  padding-bottom: 15px;
}

.split-form-career h2 {
  padding-top: 70px;
  color: white;
  font-weight: 900;
  margin-bottom: 20px; /* Add space below the heading */
  text-align: left;
  margin-right: 280px;
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 0px;
  font-size: 50px;
  @media (max-width: 460px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.split-form-career h2 span {
  color: white;
  font-weight: 600;
}

.transparent-input {
  background-color: transparent !important;
  width: 100%; /* Make input fields expand to the container's width */
  margin-bottom: 17px;
  padding-right: 150px; /* Add space between input fields */
}

.split-form-career label {
  color: rgb(228, 228, 228);
  float: left;
  height: 19px;
}

.transparent-input::placeholder {
  color: rgba(243, 243, 243, 0.3);
  font-size: 13px;
}

.btn-custom {
  padding: 10px 30px;
  background-color: transparent !important;
  border-radius: 10px;
  border-color: white;
  width: auto; /* Allow button to adjust its width based on content */
}

/* Media queries for responsiveness */

@media (maxWidth: 768px) {
  .split-form-career {
    padding: 40px; /* Increase padding for smaller screens */
    height: auto; /* Allow the form to expand in height */
  }

  .split-form-image-career {
    display: none; /* Hide the image on smaller screens */
  }
}

@media (max-width: 460px) {
  .split-form-career h2 {
    text-align: center;
  }

  .split-form-career br {
    display: none;
  }

  .split-form-career label {
    text-align: left;
    float: left;
  }

  .split-first-head {
    font-size: 20px;
    color: white;
    margin-right: 0px;
    padding-bottom: 15px;
  }

  .split-form-career h2 {
    text-align: center;
    padding-top: 0px;
    font-size: 35px;
  }

  .form-label-career-choose {
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .form-label-career-choose h6 {
    color: rgb(199, 198, 198);
    font-size: 13px;
  }

  .form-label-career-choose,
  .selected-file {
    padding-top: 10px;
    font-size: 13px;
    color: rgb(199, 198, 198);
    font-weight: 100;
  }
}

.form-market input {
  border-color: rgb(199, 198, 198);
}

.form-market textarea {
  border-color: rgb(199, 198, 198);
}

.career-left .career-left-text {
  font-size: 30px;
  font-weight: 700;
}
@media (min-width: 992px) {
  .career-left .career-left-text {
    font-size: 80px;
    margin-bottom: 200px;
  }
}

.thank-you-message-main-form{
  background-image: linear-gradient(to top, #193377, #009ADB);
}

.errorMessage {
	color: #ff0000;
	font-size: 15px;
	margin-left: 6px;
  margin-top: 5px;
}

.input-error {
	border-color: #ff0000 !important;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 20%;
  z-index:10;
}