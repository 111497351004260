.footer-head-logo img {
  width: 120px;
  display: block; 
  margin: 0 auto; 
}

// Styles for the center content within the footer
.center-content {
  h6 {
    font-weight: 600;
    font-size: 28px;
    text-align: center; 
    justify-content: center; 
    margin-bottom: 1rem; 
  }
    p {
      text-align: center; 
      margin-bottom: 0.5rem; 
    }
  }

.footerline-img {
  background-color: #182d70;
  color: white;
  margin: 0;
  height: auto;
  padding: 1%; 

  p {
    opacity: 0.5;
    text-align: center; 
  }
}

// Rights text styling
.footer-rights {
  font-size: 18px;
  justify-content: center;
  margin: auto;
  padding: 1%;
  text-align: center; 
}

// Media queries for responsive design
@media (max-width: 480px) {
  .center-content {
    h6 {
      font-weight: 600;
      font-size: 20px;
    }

    &-fh {
      justify-content: center;
    }

    p {
      font-size: 16px;
    }
  }

  .footerline-img {
    padding: 0.5%; 
  }

  .footer-rights {
    font-size: 12px;
    padding: 0.5%; 
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .center-content {
    h6 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
    }

    &-fh {
      justify-content: center;
    }

    p {
      margin-bottom: 0;
    }
  }

  .footerline-img {
    padding: 1%; 
  }

  .footer-rights {
    font-size: 12px;
    padding: 1%; 
  }
}
