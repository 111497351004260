.custom-dropdown-title-s {
  color: black;
  padding-right: 10px;
}

.bi-service {
  color: #b0b2b5;
  padding-left: 20px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: white;
}
.navbar-expand-lg .navbar-nav .lg-nav-link .nav-link {
  color: black;
}

.nav-link-service {
  color: #303030;
  text-decoration: none;
  transition: background-color 0.3s;
}

.dropdown-menu-service {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
}

.dropdown-item-service {
  color: #000000;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
}

.nav-item-service {
  font-size: 18px;
  padding-left: 40px;
}

.icons-service-navi {
  text-decoration: none;
  font-size: 15px;
  text-align: end;
  padding-top: 10px;
}
.icons-service-navis {
  padding-right: 145px;
}

.navbar-custom-service {
  padding: 0px 25px;
  box-sizing: border-box;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
  background-color: #ffffff; /* Add background color if necessary */
}

.bi-navi {
  color: #707070;
  margin-left: 25px;
  font-size: 14px;
}

.alsi-ser-nav {
  width: 67px;
  height: 100px;
}

@media (max-width: 460px) {
  .alsi-ser-nav {
    width: 40px;
    height: 50px;
  }

  .bi-navi {
    color: #707070;
    margin-left: 20px;
    font-size: 10px;
  }
  .navbar-brand-service {
    padding-left: 0;
    padding-bottom: 0px;
  }

  .navbar-custom-service {
    padding-right: 40px;
    box-sizing: border-box;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
  }

  .icons-service-navi {
    text-decoration: none;
    font-size: 14px;
    text-align: end;
    padding-top: 1%;
    padding-left: 12px;
  }

  .icons-service-navis {
    padding-right: 45px;
  }
}
.navbar-toggler-icon {
  background-image: none !important;
  border: none;
  outline: none;
  z-index: 6;

  position: relative;
  height: 4px;
  width: 40px;
  background: #2044a2;
  display: inline-block;
  transition: all 0.5s ease;
  &:before,
  &:after {
    content: "";
    height: 4px;
    width: 100%;
    background: #2044a2;
    position: absolute;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  &:before {
    top: -10px;
    right: 0;
  }
  &:after {
    bottom: -10px;
    right: 0;
  }
}

.dropdown-menu {
  top: 80% !important;
}

.serivice-navbar-container {
  .dropdown-item,
  .nav-link {
    transition: 0.3s all ease-in-out;
    margin-left: 0 !important;
  }
  .dropdown-item:hover,
  .nav-link:hover {
    font-weight: bold;
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .serivice-navbar-container {
    .dropdown-item,
    .nav-link {
      transition: 0.3s all ease-in-out;
      padding-left: 25px !important;
    }
  }
}
.nav-toggle-option,
.nav-toggle-option:focus,
.nav-toggle-option:focus,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255, 255, 255);
}

@media (min-width: 992px) {
  .nav-toggle-option,
  .nav-toggle-option:focus,
  .nav-toggle-option:focus,
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: rgb(0, 0, 0);
  }
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  width: 310px;
}
.service-navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 110;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
