.service-container-main{
  max-width: 85%;
}

.services-slider .swiper-slide {
  width: 400px;
}

.car-service-cards-heading {
  text-align: center;
  font-size: 70px;
  font-weight: 700;
}

.car-service-cards {
  margin-top: 50px;
  margin-bottom: 30px;
}

.arrows-service {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 40px;
}

.card-med {
  border-radius: 10px;
  height: 380px;
  cursor: pointer;
  width: 100%;
  min-width: 380px;
  box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.151);
  border: none;
  border-radius: 0px;
  transition: transform 0.3s ease;
}

.card-med .car-img {
  object-fit: cover;
}

.card-med:hover {
  background-color: rgb(255, 255, 255);
  border: 0;
  box-shadow: 0px 2px 0px 0px (255, 255, 255, 0.4);
  transform: scale(1.05);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  height: 342px;
}

.card-med h2 {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #193579;
}

.card-med p {
  display: block;
  color: #6a6d78;
  font-size: 14px;
  text-align: center;
  width: 70%;
}

.service-swiper-wrapper {
  max-width: 93%;
  margin: 0 auto;
}

.card-img-service{
  width: 60px;
}

@media (max-width: 1200px) {
  .car-service-cards-heading {
    font-size: 50px;
  }

  .card-img-service{
    width: 65px;
  }

  .card-med {
    height: 365px;
  }

  .card-med h2 {
    font-size: 20px;
  }

  .card-med p {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  .service-container-main{
    max-width: 98%;
  }

  .card-img-service{
    width: 110px;
  }

  .car-service-cards-heading {
    font-size: 40px;
  }

  .card-med {
    height: 650px;
  }

  .card-med h2 {
    font-size: 37px;
  }

  .card-med p {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  .service-container-main{
    max-width: 98%;
  }

  .card-img-service{
    width: 110px;
  }

  .car-service-cards-heading {
    font-size: 30px;
  }

  .card-med {
    height: 650px;
  }

  .card-med h2 {
    font-size: 16px;
  }

  .card-med p {
    font-size: 11px;
  }
}

@media (max-width: 576px) {

  .service-container-main{
    max-width: 98%;
  }

  .card-img-service{
    width: 110px;
  }

  .car-service-cards-heading {
    font-size: 25px;
  }

  .card-med {
    height: 500px;
  }

  .card-med h2 {
    font-size: 14px;
  }

  .card-med p {
    font-size: 10px;
  }
}

@media (max-width: 460px) {
  .car-service-cards-heading {
    font-size: 20px;
  }

  .card-med {
    height: 260px;
  }

  .card-med h2 {
    font-size: 22px;
  }

  .card-med p {
    font-size: 9px;
  }
}

.service-icon {
  width: 65px;
  height: auto;
}

@media (min-width: 360px) and (max-width: 480px) {
  .service-icon {
    width: 65px;
  }
}

@media (min-width: 480px) and (max-width: 760px) {
  .service-icon {
    width: 65px;
  }
}

@media (min-width: 760px) and (max-width: 920px) {
  .service-icon {
    width: 65px;
  }
}

@media (min-width: 920px) and (max-width: 1200px) {
  .service-icon {
    width: 150px;
  }
}

