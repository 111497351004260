
.differentiators-header {
  padding-bottom: 30px;
  font-size: 60px;
}

.differentiators-description {
  text-align: center;
}

.differentiators-row-one {
  padding-bottom: 50px;
}

.diff-img-setup {
  display: inline-block;
  padding-bottom: 20px;
}

.diffs-one {
  width: 100px;
  height: 100px;
  background: radial-gradient(at left, #1d346e, rgb(2, 126, 180));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.diffs-one:hover {
  animation: tilt 0.3s ease infinite;
  background: radial-gradient(at left, #213a79, rgb(2, 143, 203));
}

.diff-sem-parag {
  font-weight: 600;
}

.differentiators-img {
  max-width: 50%;
  max-height: 50%;
  padding-left: 0px;
}



/* Media Query for Small Screens */
@media (max-width: 768px) {
  .differentiators-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 0px;
  }

  .differentiators-description {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .diff-img-setup {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 0;
  }

  .differentiators-header {
    padding-bottom: 1rem;
    font-size: 35px;
  }

  .diffs-one {
    width: 175px;
    height: 175px;
    background: radial-gradient(at left, #1d346e, #009adb);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .differentiators-img {
    max-width: 50%;
    max-height: 50%;
    padding-left: 0px;
  }

  .diff-sem-parag {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding-top: 10px;
  }

  .diffs-containers {
    padding-bottom: 30px;
  }
}

/* Media Query for Medium Screens */
@media (min-width: 768px) and (max-width: 992px) {
  .differentiators-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 0px;
  }

  .differentiators-header {
    padding-bottom: 1rem;
    font-size: 30px;
  }

  .differentiators-description {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .diff-img-setup {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .diffs-one {
    width: 100px;
    height: 100px;
    background: radial-gradient(at left, #1d346e, #009adb);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .differentiators-img {
    max-width: 50%;
    max-height: 50%;
    padding-left: 0px;
  }

  .diff-sem-parag {
    font-weight: 500;
    font-size: 14px;
    margin-top: 2px;
    text-align: center;
  }

    .diffs-containers {
    padding-bottom: 30px;
  }
} 

@media (min-width: 760px) and (max-width: 1200px) {
  .differentiators-img {
    max-width: 200px;
    max-height: 200px;
    padding-left: 0px;
  }
  .diffs-one {
    width: 400px;
    height: 400px;
    background: radial-gradient(at left, #1d346e, rgb(2, 126, 180));
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
}
