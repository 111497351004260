.introduction {
  font-size: 16px;
  font-weight: 800;
  border: 1px solid rgb(239, 239, 239);
  background-color: rgb(239, 239, 239);
  padding: 30px;
  text-align: center;
}
.get-a-qoute {
  white-space: nowrap;
  cursor: pointer;
  background: #2044a2;
  padding: 8px 20px;
  color: white;
  outline: none;
  border: none;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.get-a-qoute:hover {
  padding: 8px 25px;
  background: #fff;
  color: #2044a2;
  border: 1px solid rgb(184, 183, 182);
  -webkit-box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.scrolling img {
  max-width: 100px;
}

